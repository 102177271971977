<template>
  <div class="d-flex-column align-center main mw-md">
    <el-card>
      <h3>{{ (memberSearchResult.length === 0 ? "No " : "") + "Previous Profiles Found" }}</h3>
      <div v-if="memberSearchResult.length !== 0">
        <p>We have detected existing profiles that match your details.</p>
        <p>Are any of these your profile?</p>
      </div>
    </el-card>

    <div v-for="person in memberSearchResult" :key="person.id">
      <Profile
        :person="person"
        :index="person.id"
        :thisIsMineButton="true"
        :chooseProfile="chooseProfile"
      />
    </div>
    <a
      href="#"
      v-if="memberSearchResult.length === 0"
      class="align-text-center"
      @click.prevent="backToChooseProfile"
    >
      Go back and choose an existing profile
    </a>
    <a href="#" v-else class="align-text-center" @click.prevent="unlisted">
      None of these profiles are mine
    </a>
    <!-- <a href="#"
        class="align-text-center"
        @click.prevent="unlisted">
        I had an account but it isn't shown here
      </a> -->
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import Vue from "vue";
import Profile from "@/components/profile/Profile.vue";
import store from "@/store";
import http from "@/axios/httpauth";
import msg from "@/utils/constants/msg";
import { storeProfile } from "@/utils/helpers/members";

export default {
  name: "PreviousProfileFound",
  components: {
    Profile,
  },
  props: {
    profileScreen: Boolean,
    lc: Boolean,
  },
  beforeRouteEnter(to, from, next) {
    // Search for member based on criteria from member form
    store.commit("root/LOADING", true);
    store
      .dispatch("registration/search", {
        vm: Vue.prototype,
        criteria: store.state.registration.searchCriteria,
      })
      .then(async () => {
        const profileScreen = from.path.split("/").includes("profile");
        const lc = from.path.split("/").includes("learningcentre");
        // Empty result indicates that the member needs to be created
        if (store.state.registration.memberSearchResult.length === 0) {
          if (!store.state.views.askForNewProfileCreation) {
            // Create member record
            const created = await store.dispatch("registration/create", {
              vm: Vue.prototype,
              criteria: store.state.registration.searchCriteria,
            });
            if (created) {
              if (profileScreen) {
                store.commit("root/LOADING", false);
                const rootUrl = store.state.privateLink.rootUrl;
                if (rootUrl) next({ path: rootUrl });
                else next({ name: "profile" });
              } else if (lc) {
                store.commit("root/LOADING", false);
                next({ name: "oidc-imc-oauth" });
              } else {
                http
                  .get("/nrl/api/v1/portal/members")
                  .then(async (membersResponse) => {
                    if (membersResponse.data.data) {
                      const [person] = membersResponse.data.data
                        .filter((data) => data._id === created.data)
                        .map(({ _id: id, profile, verification }) => ({
                          id,
                          verification,
                          ...profile,
                        }));
                      const setProfile = await storeProfile(person);
                      if (setProfile) {
                        store.commit("root/LOADING", false);
                        next("participantdetails");
                      } else {
                        store.commit("views/PUSH_NOTIFICATION", {
                          msg: msg.error.noProducts,
                          route: "chooseprofile",
                          type: "warning",
                        });
                        store.commit("root/LOADING", false);
                        next("chooseprofile");
                      }
                    } else {
                      store.commit("views/PUSH_NOTIFICATION", {
                        msg: msg.error.apiError,
                        route: to.name,
                        type: "warning",
                      });
                      store.commit("root/LOADING", false);
                    }
                  })
                  .catch(() => {
                    store.commit("root/LOADING", false);
                    store.commit("views/PUSH_NOTIFICATION", {
                      msg: msg.error.apiError,
                      route: to.name,
                      type: "warning",
                    });
                  });
              }
            }
          }
        } else {
          store.commit("root/LOADING", false);
          next();
        }
      });
  },
  data() {
    return {
      fromPath: undefined,
    };
  },
  computed: {
    ...mapState("registration", ["searchCriteria", "memberSearchResult"]),
    isLearningCenter() {
      return this.$route.path.split("/").includes("learningcentre");
    },
  },
  methods: {
    ...mapMutations("verify", ["VERIFICATION_OBJECT"]),
    unlisted() {
      zE.activate();
    },
    chooseProfile(person) {
      const { mobile, email, id, dob, firstName, lastName } = person; // already normalized
      this.VERIFICATION_OBJECT({
        mobile,
        email,
        id,
        dob,
        firstName,
        lastName,
      });

      this.$router.push({
        name: "verification",
        query: { isLC: this.isLearningCenter },
        params: { fromPath: store.state.privateLink.rootUrl },
      });
    },
    backToChooseProfile() {
      if (this.profileScreen) {
        this.$router.push({ name: "profile" });
      } else if (this.profileScreen) {
        this.$router.push({ name: "oidc-imc-oauth" });
      } else {
        this.$router.push({ name: "chooseprofile" });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.flex-last {
  margin-left: auto;
}

.el-select {
  width: 95%;
  margin: 20px;
}

.alert {
  max-width: $md;
  margin: auto;
}
</style>
